import React from "react"
import Container from "../components/common/Container"
import Layout from "./../components/layouts/Default"
import { H1 } from "../components/typography/Headings"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="404"
      desc="Leider existiert dieser Link nicht"
    >
      <Container>
        <H1 className="mb-8">Seite nicht gefunden</H1>
      </Container>
    </Layout>
  )
}
